import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import { TextField } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import makeStyles from "@material-ui/core/styles/makeStyles"

interface IProps {
  service?: Service
}

interface Service {
  title: string
  image: string
  description: string
}

interface ICalculateTotalAmountProps {
  initialAmount: string
  years: string
  monthlyAmount: string
  annuallyRentability: string
}

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  line: {
    width: "100%",
    position: "relative",
    marginBottom: 24,
    marginTop: 24,
  },
  label: {
    display: "inline-block",
  },
  disabledInput: {
    "& .MuiInputBase-input": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
    right: 0,
    position: "absolute",
    width: "32%",
  },
  input: { right: 0, position: "absolute", width: "32%" },
}))

const calculateMonthlyWithdraw = (totalAmount: number, annualRate: string) => {
  return (annualRate * totalAmount) / 1200
}

const calculateTotalAmount = ({
  initialAmount,
  years,
  monthlyAmount,
  annuallyRentability,
}: ICalculateTotalAmountProps) => {
  const monthlyRentability = (annuallyRentability || 0) / 1200
  const months = (years || 0) * 12

  const initialCompound =
    (initialAmount || 0) * Math.pow(1 + monthlyRentability, months)

  let monthlyCompound

  if (monthlyRentability === 0) {
    monthlyCompound = months * (monthlyAmount || 0)
  } else {
    monthlyCompound =
      ((monthlyAmount || 0) * (Math.pow(1 + monthlyRentability, months) - 1)) /
      (monthlyRentability || 1)
  }
  return Math.floor(initialCompound + monthlyCompound)
}

const CalculatorCard = ({ service }: IProps) => {
  const [initialAmount, setInitialAmount] = React.useState<string>("")
  const [yearsToInvest, setYearsToInvest] = React.useState<string>("0")
  const [monthlyAmount, setMonthlyAmount] = React.useState<string>("")
  const [
    financialFreedomAnnuallyRentability,
    setFinancialFreedomAnnuallyRentability,
  ] = React.useState<string>("")
  const [annuallyRentability, setAnnuallyRentability] = React.useState<string>(
    ""
  )

  const classes = useStyles()

  const totalAmount = calculateTotalAmount({
    initialAmount,
    years: yearsToInvest,
    annuallyRentability,
    monthlyAmount,
  })

  return (
    <Card elevation={2} style={{ marginTop: 40 }}>
      <CardContent
        style={{ textAlign: "start", minHeight: 200, minWidth: 200 }}
      >
        <div className={classes.card}>
          <div className={classes.line}>
            <Typography className={classes.label}>
              Initial Amount to Invest
            </Typography>
            <CurrencyTextField
              className={classes.input}
              id="initialAmount"
              label="Initial Amount"
              variant="outlined"
              type="text"
              value={initialAmount}
              onChange={(e, v) => setInitialAmount(v)}
            />
          </div>
          <div className={classes.line}>
            <Typography className={classes.label}>
              Years to contribute
            </Typography>

            <TextField
              className={classes.input}
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              id="yearsToInvest"
              label="Years to Invest"
              variant="outlined"
              type="text"
              value={yearsToInvest}
              onChange={e => setYearsToInvest(e.target.value)}
            />
          </div>

          <div className={classes.line}>
            <Typography className={classes.label}>
              Monthly contributions to compound
            </Typography>
            <CurrencyTextField
              className={classes.input}
              id="monthlyAmount"
              label="Monthly Contributions"
              variant="outlined"
              type="text"
              value={monthlyAmount}
              onChange={(e, v) => setMonthlyAmount(v)}
            />
          </div>

          <div className={classes.line}>
            <Typography className={classes.label}>
              Annually interest rate while contributing
            </Typography>
            <CurrencyTextField
              className={classes.input}
              id="annuallyRentability"
              label="Annually Interest"
              variant="outlined"
              type="text"
              currencySymbol="%"
              value={annuallyRentability}
              onChange={(e, v) => setAnnuallyRentability(v)}
            />
          </div>

          <div className={classes.line}>
            <Typography className={classes.label}>
              Total Amount accumulated
            </Typography>
            <CurrencyTextField
              className={classes.disabledInput}
              id="totalAmount"
              label="TotalAmount"
              textAlign="center"
              style={{ webkitTextFillColor: "#000" }}
              variant="outlined"
              disabled={true}
              type="text"
              value={totalAmount}
            />
          </div>

          <div className={classes.line}>
            <Typography className={classes.label}>
              Annually interest when financially free
            </Typography>
            <CurrencyTextField
              className={classes.input}
              id="financialFreedomAnnuallyRentability"
              label="Annually Interest"
              variant="outlined"
              type="text"
              currencySymbol="%"
              value={financialFreedomAnnuallyRentability}
              onChange={(e, v) => setFinancialFreedomAnnuallyRentability(v)}
            />
          </div>

          <div className={classes.line}>
            <Typography className={classes.label}>
              Monthly amount to withdraw without ever running out
            </Typography>
            <CurrencyTextField
              className={classes.disabledInput}
              id="monthlyWithdraw"
              style={{ webkitTextFillColor: "#000" }}
              label="monthlyWithdraw"
              textAlign="center"
              variant="outlined"
              disabled={true}
              type="text"
              value={calculateMonthlyWithdraw(
                totalAmount,
                financialFreedomAnnuallyRentability
              )}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
export default CalculatorCard
