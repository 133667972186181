import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import CalculatorCard from "./service-card"

const Hero = () => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        minHeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Grid
        item={true}
        xs={12}
        sm={12}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{
          paddingLeft: 40,
          paddingRight: 40,
          flexBasis: "100%",
        }}
      >
        <CalculatorCard />
      </Grid>
    </Grid>
  )
}
export default Hero
